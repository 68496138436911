<template>
  <div class="provide-profile-component p-lg-5 p-4">
    <div class="d-flex justify-content-center w-100">
      <img
        :src="provider.photo_perfil"
        :alt="provider.name"
        class="emblem-img"
        width="120"
        height="120"
      />
    </div>
    <div class="texts text-center mt-1 px-5">
      <h3 class="text-dark mb-0">{{ provider.title_page }}</h3>
      <small>{{ provider.subtitle_page }}</small>
      <p class="mb-0 mt-2">
        {{ provider.promise }}
      </p>
    </div>
    <div class="social-media d-flex align-items-center mt-4">
      <div v-if="provider.website" class="w-25 text-dark text-center">
        <i
          @click="showWebsite = !showWebsite"
          class="icon-website d-md-none"
        ></i>
        <a
          :href="prepareSiteUrl(provider.website)"
          target="_blank"
          class="d-none d-md-block"
        >
          <i class="icon-website"></i>
          <p>{{ provider.website }}</p>
        </a>
      </div>
      <div v-if="provider.email" class="w-25 text-center ml-lg-5 text-dark">
        <i @click="showEmail = !showEmail" class="icon-email d-md-none"></i>
        <a
          :href="'mailto:' + provider.email"
          target="_blank"
          class="d-none d-md-block"
        >
          <i class="icon-email"></i>
          <p class="d-none d-md-block">{{ provider.email }}</p>
        </a>
      </div>
      <div v-if="provider.whatsapp" class="w-25 text-center text-dark">
        <i
          @click="showWhatsapp = !showWhatsapp"
          class="icon-whatsapp d-md-none"
        ></i>
        <a
          :href="
            `https://api.whatsapp.com/send?phone=${formatPhone(
              provider.whatsapp
            )}&text=Olá, vi seu perfil no Bora Conecta e entrei em contato!`
          "
          target="_blank"
          rel="noopener noreferrer"
          class="d-none d-md-block"
        >
          <i class="icon-whatsapp"></i>
          <p class="d-none d-md-block">{{ provider.whatsapp }}</p>
        </a>
      </div>
      <div v-if="provider.instagram" class="w-25 text-center text-dark">
        <i
          @click="showInstagram = !showInstagram"
          class="icon-instagram d-md-none"
        ></i>
        <a
          :href="instagramUrl"
          target="_blank"
          rel="noopener noreferrer"
          class="d-none d-md-block"
        >
          <i class="icon-instagram"></i>
          <p class="d-none d-md-block">{{ provider.instagram }}</p>
        </a>
      </div>
    </div>
    <div class="position-relative w-100">
      <a
        v-show="showInstagram"
        :href="instagramUrl"
        target="_blank"
        class="btn btn-social position-absolute"
        >{{ provider.instagram }}</a
      >
      <a
        v-show="showWhatsapp"
        :href="
          `https://api.whatsapp.com/send?phone=${formatPhone(
            provider.whatsapp
          )}&text=Olá, vi seu perfil no Bora Conecta e entrei em contato!`
        "
        target="_blank"
        class="btn btn-social position-absolute"
        >{{ provider.whatsapp }}</a
      >
      <a
        v-show="showWebsite"
        :href="prepareSiteUrl(provider.website)"
        target="_blank"
        class="btn btn-social position-absolute"
        >{{ provider.website }}</a
      >
      <a
        v-show="showEmail"
        :href="'mailto:' + provider.email"
        target="_blank"
        class="btn btn-social position-absolute"
        >{{ provider.email }}</a
      >
    </div>
    <hr />

    <div
      v-if="provider.tags_products"
      class="products d-flex flex-wrap align-items-center mb-4"
    >
      <h6 class="mb-0 text-dark mr-3">Produtos:</h6>
      <div
        v-for="i in provider.tags_products"
        :key="i.id"
        class="card-product mx-lg-2 mr-2 mb-2"
      >
        <span>{{ i.name }}</span>
      </div>
    </div>

    <div v-if="provider.images" class="carousel mb-5">
      <carousel-provider-profile :images="provider.images" />
    </div>

    <div class="about mb-4">
      <h6>Sobre nós :</h6>
      <p>
        {{ provider.about_us }}
        <a target="blank" :href="provider.website">Adquira Já</a>
      </p>
    </div>

    <div class="buttons d-flex flex-md-row flex-column">
      <a
        :href="provider.file_pdf"
        target="_blank"
        rel="noopener noreferrer"
        class=" btn btn-outline d-flex align-items-center p-2 mr-lg-4 mb-lg-0 mb-4"
      >
        <i class="icon-pdf"></i>
        <div class="mx-3">
          <p class="mb-0 pb-0">{{ provider.title_page }}</p>
          <p class=" small mb-0 pb-0">Fazer download</p>
        </div>
        <i class="icon-download"></i>
      </a>
      <a
        :href="provider.link_video"
        target="_blank"
        rel="noopener noreferrer"
        class="btn btn-outline d-flex align-items-center p-2 "
      >
        <i class="icon-youtube-filled"></i>
        <p class="mb-0 youtube mx-3">
          {{ provider.title_page }}
        </p>
        <i class="icon-expanded"></i>
      </a>
    </div>
  </div>
</template>
<script>
import CarouselProviderProfile from "./providerprofile/CarouselProviderProfile.vue";
import { removeMask } from "@/functions/helpers.js";
export default {
  components: {
    CarouselProviderProfile
  },
  data() {
    return {
      showInstagram: false,
      showEmail: false,
      showWebsite: false,
      showWhatsapp: false
    };
  },
  props: {
    provider: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    instagramUrl() {
      if (!this.provider.instagram || !this.provider.instagram.length)
        return "";

      return `https://instagram.com/${this.provider.instagram.replace(
        "@",
        ""
      )}`;
    }
  },
  methods: {
    formatPhone(phone) {
      const formatted = removeMask(phone);
      return formatted.slice(0, 2) == "55" ? formatted : `55${formatted}`;
    },
    prepareSiteUrl(site) {
      if (!site || !site.length) return "";

      if (site.indexOf("http:") != -1) {
        const fixed = site.replace("http", "https");
        return fixed;
      }

      if (site.indexOf("https://") == -1) return `https://${site}`;

      return site;
    }
  },
  watch: {
    showInstagram() {
      if (this.showInstagram) {
        // this.showEmail = false;
        // this.showWebsite = false;
        // this.showWhatsapp = false;
        setTimeout(() => {
          this.showInstagram = !this.showInstagram;
        }, 1300);
      }
    },
    showWhatsapp() {
      if (this.showWhatsapp) {
        // this.showEmail = false;
        // this.showWebsite = false;
        // this.showInstagram = false;
        setTimeout(() => {
          this.showWhatsapp = !this.showWhatsapp;
        }, 1300);
      }
    },
    showWebsite() {
      if (this.showWebsite) {
        // this.showEmail = false;
        // this.showWhatsapp = false;
        // this.showInstagram = false;
        setTimeout(() => {
          this.showWebsite = !this.showWebsite;
        }, 1300);
      }
    },
    showEmail() {
      if (this.showEmail) {
        // this.showWebsite = false;
        // this.showWhatsapp = false;
        // this.showInstagram = false;
        setTimeout(() => {
          this.showEmail = !this.showEmail;
        }, 2500);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.provide-profile-component {
  background: #f5f5f5;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  color: #0a0a0a;
  a {
    text-decoration: none;
    font-weight: 700;
  }

  .texts {
    h3 {
      font-weight: 700;
      font-size: 18px;
      line-height: 150%;
      letter-spacing: 0.015em;
      text-transform: uppercase;
    }
    small {
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.015em;
      color: #0a0a0a;
    }
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 0.015em;
      color: #0a0a0a;
    }
  }
  .social-media {
    i {
      color: #737373;
      font-size: 18px;
      @media screen and (max-width: 767px) {
        font-size: 24px;
      }
    }
    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 0.015em;
      color: #0a0a0a;
    }
  }

  .products {
    h6 {
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 0.015em;
      color: #0a0a0a;
    }
    .card-product {
      background: #efefef;
      border: 1px solid #cfcfcf;
      border-radius: 4px;
      padding: 4px 8px;

      span {
        font-weight: 700;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: 0.015em;
        color: #737373;
      }
    }
  }

  .about {
    h6 {
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 0.015em;
      color: #000000;
    }

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 0.015em;
    }
  }

  .buttons {
    .btn-outline {
      border: 1px solid #cfcfcf;
      border-radius: 8px;
      background: transparent;
      .icon-pdf {
        color: #737373;
        font-size: 36px;
      }
      .icon-download {
        color: #737373;
        font-size: 16px;
      }
      .icon-youtube-filled {
        font-size: 40px;
        color: #737373;
      }
      .icon-expanded {
        font-size: 18px;
        color: #737373;
      }
    }

    .small {
      font-size: 12px;
      line-height: 150%;
      letter-spacing: 0.015em;
      color: #0a0a0a;
    }
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 0.015em;
      color: #0a0a0a;
    }

    .youtube {
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 0.015em;
      color: #0a0a0a;
    }
  }
  .btn-social {
    background: #0a0a0a;
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.015em;
    color: #efefef;
    z-index: 999;
    left: 50%;
    transform: translateX(-50%);
    &:active {
      background: #333333;
      border-radius: 4px;
      color: #ffcc00;
    }
  }
}
</style>
