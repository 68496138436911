<template>
  <div class="carousel-provider-profile-component">
    <carousel
      v-if="images.length"
      :perPageCustom="pages"
      :autoplay="false"
      :paginationEnabled="false"
      navigationNextLabel="<i class='icon-arrow-right'/>"
      navigationPrevLabel="<i class='icon-arrow-left'></i>"
      :navigationEnabled="true"
    >
      <slide v-for="image in images" :key="`imagem-${image.id}`">
        <img
          :src="image.image"
          alt="Serviços"
          width="256"
          height="176"
          class=" pr-1"
        />
      </slide>
    </carousel>
  </div>
</template>
<script>
export default {
  data() {
    return {
      pages: [
        [320, 1.4],
        [767, 2],
        [991, 3.2],
        [1350, 3.3]
      ]
    };
  },
  props: {
    images: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
.carousel-provider-profile-component {
  ::v-deep .VueCarousel-navigation-button {
    background: rgba(239, 239, 239, 0.5);
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-inline: 55px;
    i {
      font-size: 30px;
      color: #ffcc00;
    }
    @media screen and (max-width: 768px) {
      /* display: none; */
      margin-inline: 32px;
    }
  }
  ::v-deep .VueCarousel-navigation--disabled {
    display: none;
  }
}
</style>
