<template>
  <action-dispatcher
    action="providers/getProvider"
    :parameters="$route.params.id"
  >
    <div class="provider-view">
      <div
        :style="`background: url(${provider.cover})`"
        class="header position-relative"
      >
        <button class="btn btn-primary d-lg-none mt-5">Ver oferta</button>
        <button class="btn" @click="$router.go(-1)">
          <i class="icon-return"></i>
        </button>
      </div>
      <div class="body container">
        <div class="row mb-5 pb-5 margin-negative">
          <div class="col-lg-9 mb-lg-0 mb-4">
            <provider-profile :provider="provider" />
          </div>
          <div class="col-lg-3 mt-lg-0 mt-3">
            <promotion-card
              v-for="product in provider.products"
              :key="`offer-${product.id}`"
              :item="product"
            />
          </div>
        </div>
      </div>
    </div>
  </action-dispatcher>
</template>
<script>
import ProviderProfile from "@/components/providers/ProviderProfile.vue";
import PromotionCard from "@/components/providers/PromotionCard.vue";
import { mapState } from "vuex";
export default {
  components: {
    ProviderProfile,
    PromotionCard
  },
  computed: {
    ...mapState("providers", ["provider"])
  }
};
</script>
<style lang="scss" scoped>
.provider-view {
  .header {
    width: 100%;
    height: 0;
    padding-bottom: 20%;
    background: gray;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (max-width: 991px) {
      padding-bottom: 70%;
    }
  }
  .btn-primary {
    position: absolute;
    top: 15%;
    left: 50%;
    width: 173px;
    transform: translate(-50%, -50%);
  }

  .margin-negative {
    margin-top: -9rem;
  }
  .icon-return {
    font-size: 25px;
  }
}
</style>
