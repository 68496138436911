<template>
  <div class="promotion-card-component-provider">
    <card shape="sm" class="w-100">
      <div
        :style="`background: url(${item.cover})`"
        class="custom-card-header"
      ></div>
      <div class="custom-card-body text-center text-dark p-3">
        <h4 class="text-dark mt-3">{{ item.name }}</h4>

        <p class="description">
          {{ item.description }}
        </p>

        <h6 class="mb-3">{{ item.offer_price }}</h6>
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <a
              :href="item.url_checkout"
              target="_blank"
              class="btn btn-primary btn-block"
              >Adquirir oferta</a
            >
          </div>
        </div>
      </div>
    </card>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
<style lang="scss" scoped>
.promotion-card-component-provider {
  .custom-card-header {
    height: 0px;
    padding-bottom: 60%;
    width: 100%;
    background-color: #747474;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .custom-card-body {
    h4 {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.015em;
      color: #0a0a0a;
    }
    .description {
      font-size: 12px;
      line-height: 150%;
      letter-spacing: 0.015em;
    }
    h6 {
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
      color: #0a0a0a;
    }
  }
}
</style>
